import Swiper from 'swiper';

export function index(controller , scene){
	
	if(document.getElementById('indexswipe')){
		var swiper = new Swiper('#indexswipe', {
			speed: 600,
			slidesPerView: 'auto',
			spaceBetween: 30,
			centeredSlides: true,
			loop: true,
            autoplay: {
				delay: 1200,
				disableOnInteraction: false,
            },
			breakpoints: {
				640: {
				  spaceBetween: 15
				}
			}
		});
	}
	
	if(document.getElementById('firecanvas')){
		particlesJS("firecanvas", {
		  particles: {
			number: { value: 400, density: { enable: true, value_area: 800 } },
			color: { value: "#e60012" },
			shape: {
			  type: "polygon",
			  stroke: { width: 0, color: "#000000" },
			  polygon: { nb_sides: 3 },
			  image: { src: "img/github.svg", width: 100, height: 100 }
			},
			opacity: {
			  value: 1,
			  random: true,
			  anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
			},
			size: {
			  value: 1,
			  random: false,
			  anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
			},
			line_linked: {
			  enable: false,
			  distance: 500,
			  color: "#ffffff",
			  opacity: 0.4,
			  width: 2
			},
			move: {
			  enable: true,
			  speed: 6,
			  direction: "top",
			  random: false,
			  straight: false,
			  out_mode: "out",
			  bounce: false,
			  attract: { enable: false, rotateX: 600, rotateY: 1200 }
			}
		  },
		  interactivity: {
			detect_on: "canvas",
			events: {
			  onhover: { enable: false, mode: "repulse" },
			  onclick: { enable: false, mode: "push" },
			  resize: true
			},
			modes: {
			  grab: { distance: 400, line_linked: { opacity: 0.5 } },
			  bubble: { distance: 400, size: 4, duration: 0.3, opacity: 1, speed: 3 },
			  repulse: { distance: 200, duration: 0.4 },
			  push: { particles_nb: 4 },
			  remove: { particles_nb: 2 }
			}
		  },
		  retina_detect: true
		});

	}
}